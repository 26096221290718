import { extend } from 'vee-validate';
import vendorsService from '@/services/vendors.service';
import userService from '@/services/user.service';
import i18n from '@/config/i18n';
import { TIME_FORMAT } from '@/filters/time';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { email, numeric, required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: () => i18n.t('validation.required')
});

extend('numeric', {
  ...numeric,
  message: () => i18n.t('validation.numeric')
});

extend('email', {
  ...email,
  message: () => i18n.t('validation.invalidEmail')
});

extend('phone', {
  validate(value) {
    if (!value || value.length === 0) {
      return;
    }
    const phoneNumberObject = parsePhoneNumberFromString(value);

    if (phoneNumberObject !== undefined && phoneNumberObject.isValid()) {
      return true;
    }
    return false;
  },
  message: () => i18n.t('validation.invalidPhone')
});

extend('min', {
  validate(value, args) {
    return parseInt(value, 10) >= args.min;
  },
  params: ['min'],
  message: (field, params, data) =>
    i18n.t('validation.minimumValue', { min: params.min })
});

extend('max', {
  validate(value, args) {
    return parseInt(value, 10) <= args.max;
  },
  params: ['max'],
  message: (field, params, data) =>
    i18n.t('validation.maximumValue', { max: params.max })
});

extend('minLength', {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ['length'],
  message: (field, params, data) =>
    i18n.t('validation.minimumLength', { min: params.length })
});

extend('maxLength', {
  validate(value, args) {
    return value.length <= args.length;
  },
  params: ['length'],
  message: (field, params, data) =>
    i18n.t('validation.maximumLength', { max: params.length })
});

extend('beforeOrSameTime', {
  validate(value, args) {
    return moment(value, TIME_FORMAT).isSameOrBefore(
      moment(args.time, TIME_FORMAT)
    );
  },
  params: ['time'],
  message: (field, params, data) =>
    i18n.t('validation.sameOrBeforeTime', { time: params.time })
});

extend('afterOrSameTime', {
  validate(value, args) {
    return moment(value, TIME_FORMAT).isSameOrAfter(
      moment(args.time, TIME_FORMAT)
    );
  },
  params: ['time'],
  message: (field, params, data) =>
    i18n.t('validation.sameOrAfterTime', { time: params.time })
});

extend('afterTime', {
  validate(value, args) {
    return moment(value, TIME_FORMAT).isAfter(moment(args.time, TIME_FORMAT));
  },
  params: ['time'],
  message: (field, params, data) =>
    i18n.t('validation.afterTime', { time: params.time })
});

extend('uniqueVendorName', {
  params: ['id', 'name'],
  validate(value, { id, name }) {
    return new Promise(resolve => {
      vendorsService.findAll({ id: id, name: name }).then(response => {
        if (response.length > 0) {
          resolve({
            valid: false
          });
        } else {
          resolve({
            valid: true
          });
        }
      });
    });
  },
  message: (field, params, data) =>
    i18n.t('validation.valueAlreadyRegistered', { value: params._value_ })
});

extend('uniqueVendorEmail', {
  params: ['id', 'email'],
  validate(value, { id, email }) {
    return new Promise(resolve => {
      vendorsService.findAll({ id: id, email: email }).then(response => {
        if (response.length > 0) {
          resolve({
            valid: false
          });
        } else {
          resolve({
            valid: true
          });
        }
      });
    });
  },
  message: (field, params, data) =>
    i18n.t('validation.valueAlreadyRegistered', { value: params._value_ })
});

extend('uniqueUserEmail', {
  params: ['id', 'email'],
  validate(value, { id, email }) {
    return new Promise(resolve => {
      userService.checkEmail({ id: id, email: email }).then(response => {
        if (response.length > 0) {
          resolve({
            valid: false
          });
        } else {
          resolve({
            valid: true
          });
        }
      });
    });
  },
  message: (field, params, data) =>
    i18n.t('validation.valueAlreadyRegistered', { value: params._value_ })
});

extend('time', {
  validate(value) {
    const time = moment(value, TIME_FORMAT);
    return time.format(TIME_FORMAT) === value;
  },
  message: i18n.t('validation.invalidTime')
});

extend('divider', {
  validate(value, args) {
    return value % args.divider === 0;
  },
  params: ['divider'],
  message: (field, params, data) =>
    i18n.t('validation.divider', { value: params.divider })
});

extend('otherFieldSet', {
  params: ['otherFieldValue'],
  validate(value, { otherFieldValue }) {
    if (
      (value === null || value === undefined || value.toString() === '') &&
      otherFieldValue !== null &&
      otherFieldValue !== undefined &&
      otherFieldValue.toString() !== ''
    ) {
      return {
        required: true,
        valid: false
      };
    }

    return {
      required: false,
      valid: true
    };
  },
  computesRequired: true,
  message: (field, params, data) =>
    i18n.t('validation.otherField', { value: params.otherFieldValue })
});
